.root {
	border-radius: 1.5rem;
	outline: 1px solid #ddd;
	display: flex;
	flex-direction: column;
	font-size: 0.95em;
	gap: 1.5rem;
	padding: 1.5rem;
	text-align: justify;
	transition: 0.5s;
	border: none;
	box-shadow: 0 0 1rem #ddd;
	max-width: 30rem;
	color: inherit !important;
}

.root:hover {
	border: none;
	box-shadow: 0 0 2rem #ccc;
}

.root h3 {
	font-size: 2rem;
	margin: 0;
}

.image {
	border-radius: 0.8rem;
	height: 20rem;
	margin: 0;
	object-fit: cover;
	object-position: center;
	opacity: 0.95;
	position: relative;
	transition: 0.3s;
	width: 100%;
}

.root:hover .imageWrapper {
	opacity: 1;
}
